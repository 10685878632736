import { acceptHMRUpdate } from 'pinia'

interface State {
  successMessage: string
  formId: string
}

export const useFormStore = defineStore('form', {
  state: (): State => {
    return {
      successMessage: '',
      formId: '',
    }
  },
  getters: {},
  actions: {
    SET_SUCCESS_MESSAGE(payload: string) {
      this.successMessage = payload
    },
    SET_FORM_ID(payload: string) {
      this.formId = payload
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFormStore, import.meta.hot))
}
